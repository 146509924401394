import { TEST_OPTIONS_ROUTES } from '../../common/constant/testingOptions';

export const BASE_ROUTE = '/registration';
export const BASE_PATTERN = `${BASE_ROUTE}/:id`;
export const ROUTE_PATTERN = `${BASE_ROUTE}/:rest*`;

// Generic
export const ERROR = `${BASE_PATTERN}/error`;

// Self Registration
export const SELF_REGISTRATION_LANDING = `${BASE_PATTERN}`;
export const SELF_REGISTRATION_YOTI = `${BASE_PATTERN}/:type/scan-yoti`;
export const SELF_REGISTRATION_TEST_METHOD = `${BASE_PATTERN}/test-method`;
export const SELF_REGISTRATION_FORM = `${BASE_PATTERN}/:type/patient-form`;
export const SELF_REGISTRATION_CHOOSE_TEST_TYPE = `${BASE_PATTERN}/:type/choose-test-type`;
export const SELF_REGISTRATION_SELECT_PROVIDER = `${BASE_PATTERN}/:type/select-provider`;
export const SELF_REGISTRATION_SHARE_DETAILS = `${BASE_PATTERN}/:type/share-details`;
export const SELF_REGISTRATION_ADDITIONAL_INFO = `${BASE_PATTERN}/:type/share-additional-info`;
export const SELF_REGISTRATION_SCAN_QR_CODE = `${BASE_PATTERN}/:type/scan-qr-code`;
export const SELF_REGISTRATION_COLLECT_TEST_SAMPLE = `${BASE_PATTERN}/:type/collect-test-sample`;
export const SELF_REGISTRATION_WAIT_FOR_COLLECTION = `${BASE_PATTERN}/:type/wait-for-collection`;
export const SELF_REGISTRATION_YOTI_CALLBACK = `${BASE_ROUTE}/:id/yoti-callback`;

// Test results
const GET_TEST_RESULT = 'test-result';
export const SELF_REGISTRATION_GET_TEST_RESULT_PATTERN = `${BASE_PATTERN}/:type/${GET_TEST_RESULT}`;
export const SELF_REGISTRATION_GET_TEST_RESULT_PRE_REG = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.SELF_REGISTRATION}/${GET_TEST_RESULT}`;
export const SELF_REGISTRATION_GET_TEST_RESULT_SELF_SWAB = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.SELF_SWAB}/${GET_TEST_RESULT}`;
export const SELF_REGISTRATION_GET_TEST_RESULT_HOME_TEST_VERIFIED = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.HOME_TEST}/${GET_TEST_RESULT}`;
export const SELF_REGISTRATION_GET_TEST_RESULT_HOME_TEST_UNVERIFIED = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.HOME_TEST_UNVERIFIED}/${GET_TEST_RESULT}`;

// Send your sample
const SEND_YOUR_SAMPLE = 'send-your-sample';
export const SELF_REGISTRATION_SEND_YOUR_SAMPLE_PATTERN = `${BASE_PATTERN}/:type/${SEND_YOUR_SAMPLE}`;
export const SELF_REGISTRATION_SEND_YOUR_SAMPLE_PRE_REG = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.SELF_REGISTRATION}/${SEND_YOUR_SAMPLE}`;
export const SELF_REGISTRATION_SEND_YOUR_SAMPLE_SELF_SWAB = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.SELF_SWAB}/${SEND_YOUR_SAMPLE}`;

// Thank you page
const THANK_YOU = 'thank-you';
export const SELF_REGISTRATION_THANK_YOU_PATTERN = `${BASE_PATTERN}/:type/${THANK_YOU}`;
export const SELF_REGISTRATION_THANK_YOU_PRE_REG = `${BASE_PATTERN}/${TEST_OPTIONS_ROUTES.SELF_REGISTRATION}/${THANK_YOU}`;
export const SELF_REGISTRATION_THANK_YOU_DEFAULT = `${BASE_PATTERN}/:type/${THANK_YOU}`;

// Home test samples
export const SELF_REGISTRATION_CHECK_SAMPLE_TAKEN = `${BASE_PATTERN}/:type/check-sample-taken`;
export const SELF_REGISTRATION_REPORT_TEST = `${BASE_PATTERN}/:type/report-sample`;
export const SELF_REGISTRATION_REPORT_IMAGE_UPLOAD = `${BASE_PATTERN}/:type/report-image`;

// External
export const YOTI_PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=com.yoti.mobile.android.live';
export const YOTI_APPSTORE_LINK =
  'https://apps.apple.com/gb/app/yoti-your-digital-identity/id983980808';
