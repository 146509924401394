import './index.scss';

/* eslint-disable global-require, import/first */
if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

import { h, render } from 'preact';
import { Suspense, lazy } from 'preact/compat';
import Router from 'preact-router';
import { Loading } from '@yoti/ui-kit-components-react/loading';
import { ROUTE_PATTERN } from './registration/constant/routes';
import ErrorBoundary from './common/components/errorBoundary/errorBoundary';
import { setupInterceptors } from './common/services/interceptors';

const Org = lazy(() => import(/* webpackChunkName: "org" */ './organisation/index'));
const Patient = lazy(() => import(/* webpackChunkName: "reg" */ './registration/index'));

setupInterceptors();

render(
  <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <Router>
        <Org default />
        <Patient path={ROUTE_PATTERN} />
      </Router>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);
